import React, { useContext } from "react";
import { Box } from "@mui/material";
import ClaimConnect from "./ClaimConnect";
import ClaimMain from "./ClaimMain";
import { useAccount } from "wagmi";

function Claim({ setopenModal }) {
  const { address } = useAccount();

  return (
    <Box>
      {address ? <ClaimMain /> : <ClaimConnect setopenModal={setopenModal} />}
    </Box>
  );
}

export default Claim;
