import React, { useState, useEffect } from "react";
import "./App.css";
import Web3 from "web3";
import NetworkChange from "./networkSwitch";
import PaymentPage from "./components/paymentPage/payment";
import { Routes, Route } from "react-router-dom";
import Claim from "./components/Claim/Claim";
import Wallet from "./Wallet";

function App() {
  const [openModal, setopenModal] = useState(false);
  const [switchNetwork, setswitchNetwork] = useState(false);
  const web3 = new Web3(
    Web3.givenProvider
      ? Web3.givenProvider
      : "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
  );
  let chain = async () => {
    try {
      if (window.web3) {
        const chainid = await web3.eth.getChainId();
        console.log(chainid, "chainid");
        if (+chainid !== 1) {
          setswitchNetwork(true);
        }
      }
    } catch (error) {
      console.log("err");
    }
  };
  useEffect(() => {
    chain();
  }, []);

  return (
    <>
      <Wallet setOpen={setopenModal} open={openModal} />
      <NetworkChange open={switchNetwork} setOpen={setswitchNetwork} />
      <Routes>
        <Route
          exact
          path="/"
          element={<PaymentPage setopenModal={setopenModal} />}
        />
        <Route path="/claim" element={<Claim setopenModal={setopenModal} />} />
      </Routes>
    </>
  );
}

export default App;
