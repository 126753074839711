import React from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useAccount, useConnect } from "wagmi";
import { withStyles } from "@mui/styles";

const StyledModal = withStyles(() => ({
  root: {
    "& .MuiDialog-root": {
      zIndex: "1301 !important",
    },
    "&.MuiDialog-container": {
      overflowY: "hidden !important",
    },
    "& .MuiDialog-paperScrollPaper": {
      backgroundColor: "tranparent !important",
      height: "auto",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    "& .dialoge__content__section": {
      background: "#EDF1FE",
    },
    "& .MuiDialogContent-root": {
      padding: "20px",
    },
  },
}))(Dialog);
function Modal({ setOpen, open }) {
  const { address } = useAccount();
  const { connect, connectors, isLoading, pendingConnector } = useConnect();
  const matches = useMediaQuery("(max-width:600px)");

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <div className="modal__main__container">
        <StyledModal
          open={open}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className="dialoge__content__section">
            <div
              style={{
                textAlign: "center",
              }}
            >
              <Typography
                style={{
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "28px",
                  color: "#000",
                  fontFamily: "ComicSans",
                }}
                variant="h1"
              >
                Connect to a wallet
              </Typography>
              <div>
                {connectors.map((connector) => (
                  <Box
                    onClick={() => {
                      setOpen(false);
                      connect({ connector });
                    }}
                    sx={{
                      width: matches ? "200px" : "300px",
                      height: matches ? "45px" : "50px",
                      border: "4px solid #eee",
                      background: "#fff",
                      fontSize: { md: "18px", xs: "14px" },
                      fontWeight: "700",
                      cursor: "pointer",
                      "&:hover": {
                        background: "#0071BDa1",
                      },
                      borderRadius: "50px",
                      padding: "5px",
                      display:!connector.ready ? "none" : "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "20px auto",
                      paddingRight: "30px",
                      paddingLeft: "30px",
                    }}
                    disabled={!connector.ready}
                    key={connector.id}
                  >
                    <Typography
                      style={{
                        fontWeight: 500,
                        fontStyle: "normal",
                        fontSize: matches ? "14px" : "18px",
                        color: "#000",
                      }}
                      variant="h1"
                    >
                      {connector.name}
                      {!connector.ready && " (unsupported)"}
                      {isLoading &&
                        connector.id === pendingConnector?.id &&
                        " (connecting)"}
                    </Typography>
                  </Box>
                ))}

                {/* {error && <div>{error.message}</div>} */}
              </div>
            </div>
          </DialogContent>
        </StyledModal>
        {/* </Slide> */}
      </div>
    </div>
  );
}

export default Modal;
