import { Box, Container, Button, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import Dialog from "@mui/material/Dialog";
import Stepper from "../stepper/stepperMain";
import { useAccount, useConnect, useSigner } from "wagmi";

import { InjectedConnector } from "wagmi/connectors/injected";
import { useLocation } from "react-router-dom";
import { ToastNotify } from "../../ToastNotify";
import { useMLMContract } from "../../ConnectivityAss/hooks";
import ClaimStepper from "./ClaimStepper";
import { formatUnits } from "@ethersproject/units";

function ClaimMain() {
  const { address } = useAccount();
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  });
  const { data: signer } = useSigner();
  const MLMContract = useMLMContract(signer);
  const [open, setOpen] = React.useState(false);
  const [amount, setAmount] = React.useState();
  const [username, setUsername] = React.useState();

  const matches = useMediaQuery("(min-width : 630px)");
  const [alertState, setAlertState] = React.useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const { handleSubmit } = useForm();
  const onSubmit = async () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const getData = async () => {
    const amount = await MLMContract.approvedAmount(address);
    setAmount(+formatUnits(amount.toString(), 6));
    const name = await MLMContract.registered(address);
    console.log(name, "dataa");
    setUsername(name.name);
  };
  useEffect(() => {
    getData();
  }, [address]);

  return (
    <Box py={2}>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />

      <Container>
        <Box>
          <img src="images/seaLogo.png" width="130px" alt="" />
        </Box>
        <Box pt={8}>
          <Container maxWidth="md">
            <Box
              bgcolor="#EDF1FE"
              py={4}
              px={matches ? 6 : 4}
              borderRadius="5px"
              boxShadow={3}
            >
              <Box
                textAlign="center"
                fontSize={{ md: "30px", xs: "22px" }}
                fontFamily="Inter"
                fontWeight="500"
                color="black"
              >
                SE@ Payment System
              </Box>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box mt={4}>
                  <Box color="black" fontFamily="Inter" mb={1}>
                    Nickname
                  </Box>

                  <input
                    placeholder="John Doe"
                    type="text"
                    style={{
                      width: "85%",
                      height: "38px",
                      border: "1px solid #E5E5E5",
                      paddingLeft: "15px",
                      fontSize: matches ? "20px" : "25px",
                      fontWeight: 700,
                      color: "#727272",
                    }}
                    value={username}
                    readOnly
                  />
                </Box>
                <Box mt={1}>
                  <Box color="black" fontFamily="Inter" mb={1}>
                    Wallet Address
                  </Box>
                  <input
                    placeholder="0x567890ds98f675eda70990s8dfa0"
                    type="text"
                    style={{
                      width: "85%",
                      height: "38px",
                      border: "1px solid #E5E5E5",
                      paddingLeft: "15px",
                      fontSize: matches ? "20px" : "25px",
                      fontWeight: 700,
                      color: "#727272",
                    }}
                    readOnly
                    value={address}
                  />
                </Box>
                <Box mt={1}>
                  <Box color="black" fontFamily="Inter" mb={1}>
                    Amount to claim (USDT)
                  </Box>
                  <input
                    placeholder="1000"
                    type="number"
                    style={{
                      width: "85%",
                      height: "38px",
                      border: "1px solid #E5E5E5",
                      paddingLeft: "15px",
                      fontSize: matches ? "20px" : "25px",
                      fontWeight: 700,
                      color: "#727272",
                    }}
                    readOnly
                    value={amount}
                  />
                </Box>
                <Box mt={5} textAlign="center">
                  {console.log(!amount && !username)}
                  <button
                    disabled={!amount || !username}
                    type="submit"
                    style={{
                      cursor:
                        amount === 0 || !username ? "not-allowed" : "pointer",
                      background: "#0071BD",
                      fontFamily: "Inter",
                      fontSize: matches ? "20px" : "25px",
                      fontWeight: "700",
                      color: "white",
                      width: "90%",
                      height: "45px",
                      border: "none",
                      "&:hover": {
                        background: "#0071BDa1",
                      },
                    }}
                  >
                    Claim
                  </button>

                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      background: "#1ABB9C",
                      fontFamily: "Inter",
                      fontSize: { md: "23px", xs: "18px" },
                      fontWeight: "700",
                      color: "#fff",
                      borderRadius: "10px",
                      opacity: "0.5",
                      mixBlendMode: "darken",
                      width: "100%",
                      marginTop: "12px",
                      height: "45px",
                      "&:hover": {
                        background: "#0071BDa1",
                      },
                    }}
                  >
                    Address: <Box component="span">{address}</Box>
                  </Box>
                </Box>
              </form>
            </Box>
          </Container>
        </Box>
      </Container>
      <Dialog open={open} onClose={handleClose} style={{ width: "100%" }}>
        <ClaimStepper amount={amount} handleClose={handleClose} />
      </Dialog>
    </Box>
  );
}

export default ClaimMain;
