import React, { useContext } from "react";
import { Box, Container, useMediaQuery, Button } from "@mui/material";
import { useConnect } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";

function ClaimConnect({ setopenModal }) {
  const matches = useMediaQuery("(min-width : 630px)");

  const { connect } = useConnect({
    connector: new InjectedConnector(),
  });

  return (
    <Box>
      <Container>
        <Box pt={8}>
          <Container maxWidth="md">
            <Box
              bgcolor="#EDF1FE"
              py={4}
              px={matches ? 6 : 4}
              borderRadius="5px"
              boxShadow={3}
            >
              <Box
                textAlign="center"
                fontSize={{ md: "30px", xs: "22px" }}
                fontFamily="Inter"
                fontWeight="500"
                color="black"
              >
                SE@ Payment System
              </Box>
              <Box textAlign="center" my={5}>
                <img
                  src="images/seaLogo.png"
                  width={matches ? "90px" : "110px"}
                  alt=""
                />

                <Box
                  my={5}
                  fontSize={{ xs: "20px", sm: "25px" }}
                  fontWeight={400}
                  color="#000"
                >
                  Please connect using your wallet to claim your rewards
                </Box>
                <Button
                  onClick={() => setopenModal(true)}
                  sx={{
                    background: "#0071BD",
                    fontFamily: "Inter",
                    fontSize: { md: "18px", xs: "14px" },
                    fontWeight: "700",
                    color: "white",
                    width: "90%",
                    cursor: "pointer",
                    "&:hover": {
                      background: "#0071BDa1",
                    },
                  }}
                >
                  connect
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
      </Container>
    </Box>
  );
}

export default ClaimConnect;
