import * as React from "react";
import { Box, useMediaQuery } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import { parseUnits } from "@ethersproject/units";
import { useMLMContract, useTokenContract } from "../../ConnectivityAss/hooks";
import { contractAddress } from "../../ConnectivityAss/environment";
// import { AppContext, gasEstimationForAll } from "../../utils";
import { useSigner } from "wagmi";
import Loading from "../Loader/Loader";
import { ToastNotify } from "../../ToastNotify";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";

const steps = ["Initiated", "Progress", "Confirmed"];

export default function ClaimStepper({ amount, handleClose }) {
  const { data: signer } = useSigner();
  const MLMContract = useMLMContract(signer);
  const TokenContract = useTokenContract(signer);
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setloading] = React.useState(false);
  const [skipped, setSkipped] = React.useState(new Set());
  const today = moment();
  const [alertState, setAlertState] = React.useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const claimHandler = async () => {
    try {
      setloading(true);
      handleNext();
      const tx = await MLMContract.claim();
      await tx.wait();
      setAlertState({
        open: true,
        message: `The claim has been executed successfully`,
        severity: "success",
      });
      handleNext();
      handleNext();
      setloading(false);
    } catch (error) {
      console.log(error);
      if (error?.data?.message) {
        setAlertState({
          open: true,
          message: error?.message,
          severity: "error",
        });
      } else if (error?.reason) {
        setAlertState({
          open: true,
          message: error?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: error?.message,
          severity: "error",
        });
      }
      setloading(false);
    }
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const matches = useMediaQuery("(min-width : 900px)");

  return (
    <Box
      mb={5}
      sx={{
        backgroundColor: "white",
        width: "100%",
        color: "black",
      }}
    >
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Container>
        <Box
          mt={2}
          sx={{
            color: "black",
            //  fontWeight : 'bold',
            fontSize: { md: "16px", xs: "14px" },
            fontFamily: "Inter",
            textAlign: "center",
          }}
        >
          {activeStep === steps.length
            ? "Transfered Successfully"
            : "Claim In Progress"}
        </Box>

        <Box py={3} display="flex" justifyContent="space-between">
          <Box
            sx={{
              color: "black",
              //  fontWeight : 'bold',
              fontSize: { md: "16px", xs: "14px" },
              fontFamily: "Inter",
              // textAlign : 'center'
            }}
          >
            Transfer Amount
          </Box>
          <Box
            sx={{
              color: "black",
              //  fontWeight : 'bold',
              fontSize: { md: "16px", xs: "14px" },
              fontFamily: "Inter",
              // textAlign : 'center'
            }}
          >
            {amount ? amount : "0"} Usdt
          </Box>
        </Box>

        <Stepper
          sx={{
            display: "flex",
            flexDirection: matches ? "row" : "column",
            justifyContent: "space-between",
            height: !matches ? "120px" : "",
            alignItems: "flex-start",
          }}
          activeStep={activeStep}
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};

            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel
                  sx={{ color: "black", marginTop: "-12px" }}
                  {...labelProps}
                >
                  <Box color="black">{label}</Box>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length && (
          <React.Fragment>
            <Typography
              sx={{ mt: 2, mb: 1 }}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <img src="/images/tick.png" width="100px" alt="" />
              <Box
                mt={3}
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  fontSize: { md: "20px", xs: "16px" },
                  fontFamily: "Inter",
                }}
              >
                Transfer Completed
              </Box>
              <Box px={4} textAlign="center">
                The claiming process has been executed successfully
              </Box>
              <Box
                mt={5}
                mb={2}
                sx={{
                  background: "#2887C5",
                  color: "white",
                  fontSize: { md: "20px", xs: "18px" },
                  fontFamily: "Inter",
                  padding: "5px 12px 5px 12px",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                  cursor: "pointer",
                  "&:hover": {
                    background: "#2887C5a1",
                  },
                }}
                onClick={handleClose}
              >
                Done
              </Box>
            </Typography>
          </React.Fragment>
        )}

        {activeStep === 1 && (
          <React.Fragment>
            <Box textAlign="center" sx={{ mt: 5, mb: 3 }}>
              {/* <CircularProgress color="inherit" /> */}
            </Box>
          </React.Fragment>
        )}
        {activeStep === 0 && (
          <React.Fragment>
            <Box pt={4} textAlign="center">
              <Box
                mt={3}
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  fontSize: { md: "20px", xs: "16px" },
                  fontFamily: "Inter",
                }}
              >
                Transfer
              </Box>
              <Box
                pt={2}
                sx={{
                  color: "black",
                  width: "70%",
                  margin: "auto",
                  fontSize: { md: "20px", xs: "15px" },
                  fontFamily: "Inter",
                }}
              >
                Are you sure to claim?
              </Box>
              <Box marginTop="10%" textAlign="center" color="black">
                <Button
                  onClick={() => claimHandler()}
                  sx={{
                    background: "#2887C5",
                    color: "white",
                    fontSize: { md: "20px", xs: "18px" },
                    fontFamily: "Inter",
                    padding: "5px 12px 5px 12px",
                    borderRadius: "10px",
                    textTransform: "capitalize",
                    "&:hover": {
                      background: "#2887C5a1",
                    },
                  }}
                >
                  Claim
                </Button>
              </Box>
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                pt: 2,
              }}
            >
              <Button
                sx={{
                  background: "#2887C5",
                  "&:hover": {
                    background: "#2887C5a1",
                  },
                }}
                onClick={handleNext}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box> */}
          </React.Fragment>
        )}
      </Container>
    </Box>
  );
}
