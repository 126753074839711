import { Box, Container, Button, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Dialog from "@mui/material/Dialog";
import Stepper from "../stepper/stepperMain";
import { useAccount, useConnect, useSigner } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { formatUnits } from "@ethersproject/units";
import { ToastNotify } from "../../ToastNotify";
import { useMLMContract } from "../../ConnectivityAss/hooks";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ setopenModal }) => {
  const { address, isConnected } = useAccount();
  const { data: signer } = useSigner();
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  });
  const MLMContract = useMLMContract(signer);
  let location = useLocation();
  let history = useNavigate();
  let [params, setParams] = useSearchParams();
  const [taxFee, setTaxFee] = useState();

  const matches = useMediaQuery("(min-width : 630px)");
  const [open, setOpen] = React.useState(false);
  const [enteredData, setEnteredData] = React.useState();
  const [actualdata, setactualdata] = React.useState();
  const [paymentState, setpaymentState] = React.useState();
  const [alertState, setAlertState] = React.useState({
    open: false,
    message: "",
    severity: undefined,
  });
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (
      searchParams.has("username") &&
      searchParams.has("wallet_Address") &&
      searchParams.has("amount") &&
      searchParams.has("product_id") &&
      searchParams.has("order_id") &&
      searchParams.has("months") &&
      searchParams.has("date") &&
      searchParams.has("plan_name") &&
      searchParams.has("plan_value")
    ) {
      let arr = [];
      const username = searchParams.get("username");
      const wallet_Address = searchParams.get("wallet_Address");
      const amount = searchParams.get("amount");
      const product_id = searchParams.get("product_id");
      const order_id = searchParams.get("order_id");
      const months = searchParams.get("months");
      const date = searchParams.get("date");
      const index_id = searchParams.get("plan_name");
      const value = searchParams.get("plan_value");

      if (wallet_Address.length === 42) {
        const obj = {
          username,
          wallet_Address,
          amount,
          product_id,
          order_id,
          months,
          date,
          index_id,
          value,
        };
        arr.push(obj);
        let key = amount;
        localStorage.setItem("params", JSON.stringify(obj));
        setParams({ key: "dh46hsm41117dE328eb851F29941c8689c50851E3a82154" });
        setEnteredData([...arr]);
      } else {
        setAlertState({
          open: true,
          message: "Please check your wallet address",
          severity: "error",
        });
      }
    }
  }, [location]);
  useEffect(() => {
    const actualData = JSON.parse(localStorage.getItem("params"));
    setactualdata(actualData);
  }, [enteredData]);

  useEffect(() => {
    getValues();
  }, [actualdata]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async () => {
    if (actualdata) {
      let plan = actualdata.index_id + actualdata.value;
      console.log(plan, "plan");
      let value = await MLMContract.plan(plan);
      if (
        actualdata.wallet_Address.toLocaleLowerCase() ===
        address.toLocaleLowerCase()
      ) {
        // if (+value === +actualdata.value) {
        console.log(+value, +actualdata.value, "value");
        setOpen(true);
        // } else {
        //   setAlertState({
        //     open: true,
        //     message: "Amount is not according to plan.",
        //     severity: "error",
        //   });
        // }
      } else {
        setAlertState({
          open: true,
          message: `Please connect your wallet to ${actualdata.wallet_Address}`,
          severity: "error",
        });
      }
    }
  };

  const getValues = async () => {
    if (actualdata) {
      let state = await MLMContract.paid(actualdata.wallet_Address);
      setpaymentState(state);
      let fee1 = await MLMContract.registrationFee1();
      let fee2 = await MLMContract.registrationFee2();
      console.log(+formatUnits(fee1, 6));
      if (state === false) {
        setTaxFee(+formatUnits(fee1, 6));
      } else {
        setTaxFee(+formatUnits(fee2, 6));
      }
    }
  };
  const handleClose = () => setOpen(false);

  return (
    <Box py={2}>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />

      <Container>
        <Box>
          <img src="images/seaLogo.png" width="130px" alt="" />
        </Box>

        <Box pt={8}>
          <Container maxWidth="sm">
            <Box
              bgcolor="#EDF1FE"
              py={4}
              px={matches ? 6 : 4}
              borderRadius="5px"
              boxShadow={3}
            >
              <Box
                textAlign="center"
                fontSize={{ md: "30px", xs: "22px" }}
                fontFamily="Inter"
                fontWeight="500"
                color="black"
              >
                SE@ Payment System
              </Box>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box mt={4}>
                  <Box color="black" fontFamily="Inter">
                    Nickname
                  </Box>

                  <input
                    placeholder="John Doe"
                    type="text"
                    style={{ width: "85%", height: "28px" }}
                    value={actualdata && actualdata.username}
                    readOnly
                  />
                  {errors.name && <p>{errors.name.message}</p>}
                </Box>
                <Box mt={1}>
                  <Box color="black" fontFamily="Inter">
                    Wallet Address
                  </Box>
                  <input
                    placeholder="0x567890ds98f675eda70990s8dfa0"
                    type="text"
                    style={{ width: "85%", height: "28px" }}
                    readOnly
                    value={actualdata && actualdata.wallet_Address}
                  />
                </Box>
                <Box mt={1}>
                  <Box color="black" fontFamily="Inter">
                    Amount (USDT)
                  </Box>
                  <input
                    placeholder="1000"
                    type="number"
                    style={{ width: "85%", height: "28px" }}
                    readOnly
                    value={actualdata ? actualdata?.value : "0"}
                  />
                  <Box color="#000">Fee: {taxFee}</Box>
                </Box>
                {/* {console.log(enteredData)} */}
                <Box mt={5} textAlign="center">
                  {isConnected ? (
                    <Button
                      type="submit"
                      sx={{
                        background: "#0071BD",
                        fontFamily: "Inter",
                        fontSize: { md: "18px", xs: "14px" },
                        fontWeight: "700",
                        color: "white",
                        width: "90%",
                        "&:hover": {
                          background: "#0071BDa1",
                        },
                      }}
                      disabled={actualdata?.length === 0}
                    >
                      Pay
                    </Button>
                  ) : (
                    <Button
                      onClick={() => setopenModal(true)}
                      sx={{
                        background: "#0071BD",
                        fontFamily: "Inter",
                        fontSize: { md: "18px", xs: "14px" },
                        fontWeight: "700",
                        color: "white",
                        width: "90%",
                        "&:hover": {
                          background: "#0071BDa1",
                        },
                      }}
                    >
                      connect
                    </Button>
                  )}
                </Box>
              </form>
            </Box>
          </Container>
        </Box>
      </Container>
      <Dialog open={open} onClose={handleClose} style={{ width: "100%" }}>
        <Stepper
          taxFee={taxFee}
          actualdata={actualdata}
          paymentState={paymentState}
        />
      </Dialog>
    </Box>
  );
};
